(function ($) {
  // Global variable.
  // Helps us determine if the current modal is being queued for display.
  var modalQueued = false;

  // Bind the live 'click' event to all anchor elemnets with the data-reveal-id attribute.
  $(document).on('click', 'a[data-reveal-id]', function (event) {
    // Prevent default action of the event.
    event.preventDefault();
    // Get the clicked anchor data-reveal-id attribute value.
    var modalLocation = $(this).attr('data-reveal-id');
    // Find the element with that modalLocation id and call the reveal plugin.
    $('#' + modalLocation).reveal($(this).data());
  });

  /*
   * @module reveal
   * @property {Object} [options] Reveal options
   */
  $.fn.reveal = function (options) {
    // Cache the document object.
    var $doc = $(document),

    // Default property values.
    defaults = {
      /*
       * Possible options: fade, fadeAndPop, none
       *
       * @property animation
       * @type {String}
       * @default fadeAndPop
       */
      animation: 'fade',
      /*
       * Speed at which the reveal should show. How fast animtions are.
       *
       * @property animationSpeed
       * @type {Integer}
       * @default 300
       */
      animationSpeed: 300,
      /*
       * Should the modal close when the background is clicked?
       *
       * @property closeOnBackgroundClick
       * @type {Boolean}
       * @default true
       */
      closeOnBackgroundClick: true,
      /*
       * Specify a class name for the 'close modal' element.
       * This element will close an open modal.
       *
       @example
       <a href='#close' class='close-reveal-modal'>Close Me</a>
       *
       * @property dismissModalClass
       * @type {String}
       * @default close-reveal-modal
       */
      dismissModalClass: 'close-reveal-modal',

      closeOnEsc: true,
      /*
       * Specify a callback function that triggers 'before' the modal opens.
       *
       * @property open
       * @type {Function}
       * @default function(){}
       */
      open: $.noop,
      /*
       * Specify a callback function that triggers 'after' the modal is opened.
       *
       * @property opened
       * @type {Function}
       * @default function(){}
       */
      opened: $.noop,
      /*
       * Specify a callback function that triggers 'before' the modal prepares to close.
       *
       * @property close
       * @type {Function}
       * @default function(){}
       */
      close: $.noop,
      /*
       * Specify a callback function that triggers 'after' the modal is closed.
       *
       * @property closed
       * @type {Function}
       * @default function(){}
       */
      closed: $.noop
    };

    // Extend the default options.
    // This replaces the passed in option (options) values with default values.
    options = $.extend({}, defaults, options);

    // Apply the plugin functionality to each element in the jQuery collection.
    return this.not('.reveal-modal.open').each(function () {
      // Cache the modal element
      var modal = $(this),

      // Get the current css 'top' property value in decimal format.
      topMeasure = parseInt(modal.css('top'), 10),

      // Calculate the top offset.
      topOffset = modal.height() + topMeasure,

      // Helps determine if the modal is locked.
      // This way we keep the modal from triggering while it's in the middle of animating.
      locked = false,

      // Get the modal background element.
      modalBg = $('.reveal-modal-bg'),

      // Show modal properties
      cssOpts = {
        // Used, when we show the modal.
        open: {
          // Set the 'top' property to the document scroll minus the calculated top offset.
          top: 0,
          // Opacity gets set to 0.
          opacity: 0,
          // Show the modal
          visibility: 'visible',
          // Ensure it's displayed as a block element.
          display: 'block'
        },
        // Used, when we hide the modal.
        close: {
          // Set the default 'top' property value.
          top: topMeasure,
          // Has full opacity.
          opacity: 1,
          // Hide the modal
          visibility: 'hidden',
          // Ensure the elment is hidden.
          display: 'none'
        }

      },

      // Initial closeButton variable.
      $closeButton;

      // Do we have a modal background element?
      if (modalBg.length === 0) {
        // No we don't. So, let's create one.
        modalBg = $('<div />', { class: 'reveal-modal-bg' })
        // Then insert it after the modal element.
        .insertAfter(modal);
        // Now, fade it out a bit.
        modalBg.fadeTo('fast', 0.8);
      }

      // Helper Methods

      /*
       * Unlock the modal for animation.
       *
       * @method unlockModal
       */
      function unlockModal() {
        locked = false;
      }

      /*
       * Lock the modal to prevent further animation.
       *
       * @method lockModal
       */
      function lockModal() {
        locked = true;
      }

      /*
       * Closes all open modals.
       *
       * @method closeOpenModal
       */
      function closeOpenModals() {
        // Get all reveal-modal elements with the .open class.
        var $openModals = $('.reveal-modal.open');
        // Do we have modals to close?
        if ($openModals.length === 1) {
          // Set the modals for animation queuing.
          modalQueued = true;
          // Trigger the modal close event.
          $openModals.trigger('reveal:close');
        }
      }
      /*
       * Animates the modal opening.
       * Handles the modal 'open' event.
       *
       * @method openAnimation
       */
      function openAnimation() {
        // First, determine if we're in the middle of animation.
        if (!locked) {
          // We're not animating, let's lock the modal for animation.
          lockModal();
          // Close any opened modals.
          closeOpenModals();
          // Now, add the open class to this modal.
          modal.addClass('open');

          // Are we executing the 'fadeAndPop' animation?
          if (options.animation === 'fadeAndPop') {
            // Yes, we're doing the 'fadeAndPop' animation.
            // Okay, set the modal css properties.
            // Set the 'top' property to the document scroll minus the calculated top offset.
            cssOpts.open.top = $doc.scrollTop() - topOffset;
            // Flip the opacity to 0.
            cssOpts.open.opacity = 0;
            // Set the css options.
            modal.css(cssOpts.open);
            // Fade in the background element, at half the speed of the modal element.
            // So, faster than the modal element.
            modalBg.fadeIn(options.animationSpeed / 2);

            // Let's delay the next animation queue.
            // We'll wait until the background element is faded in.
            modal.delay(options.animationSpeed / 2)
            // Animate the following css properties.
            .animate({
              // Set the 'top' property to the document scroll plus the calculated top measure.
              top: $doc.scrollTop() + topMeasure + 'px',
              // Set it to full opacity.
              opacity: 1

            },
            // Fade speed.
            options.animationSpeed,
            // End of animation callback.
            function () {
              // Trigger the modal reveal:opened event.
              // This should trigger the functions set in the options.opened property.
              modal.trigger('reveal:opened');
            }); // end of animate.
          } // end if 'fadeAndPop'

          // Are executing the 'fade' animation?
          if (options.animation === 'fade') {
            // Yes, were executing 'fade'.
            // Okay, let's set the modal properties.
            cssOpts.open.top = $doc.scrollTop() + topMeasure;
            // Flip the opacity to 0.
            cssOpts.open.opacity = 0;
            // Set the css options.
            modal.css(cssOpts.open);
            // Fade in the modal background at half the speed of the modal.
            // So, faster than modal.
            modalBg.fadeIn(options.animationSpeed / 2);

            // Delay the modal animation.
            // Wait till the modal background is done animating.
            modal.delay(options.animationSpeed / 2)
            // Now animate the modal.
            .animate({
              // Set to full opacity.
              opacity: 1
            },

            // Animation speed.
            options.animationSpeed,

            // End of animation callback.
            function () {
              // Trigger the modal reveal:opened event.
              // This should trigger the functions set in the options.opened property.
              modal.trigger('reveal:opened');
            });
          } // end if 'fade'

          // Are we not animating?
          if (options.animation === 'none') {
            // We're not animating.
            // Okay, let's set the modal css properties.
            // Set the top property.
            cssOpts.open.top = $doc.scrollTop() + topMeasure;
            // Set the opacity property to full opacity, since we're not fading (animating).
            cssOpts.open.opacity = 1;
            // Set the css property.
            modal.css(cssOpts.open);
            // Show the modal Background.
            modalBg.css({ display: 'block' });
            // Trigger the modal opened event.
            modal.trigger('reveal:opened');
          } // end if animating 'none'
        } // end if !locked
      } // end openAnimation


      function openVideos() {
        var video = modal.find('.flex-video');
        var iframe = video.find('iframe');
        if (iframe.length > 0) {
          iframe.attr('src', iframe.data('src'));
          video.fadeIn(100);
        }
      }

      // Bind the reveal 'open' event.
      // When the event is triggered, openAnimation is called
      // along with any function set in the options.open property.
      modal.on('reveal:open.reveal', openAnimation);
      modal.on('reveal:open.reveal', openVideos);

      /*
       * Closes the modal element(s)
       * Handles the modal 'close' event.
       *
       * @method closeAnimation
       */
      function closeAnimation() {
        // First, determine if we're in the middle of animation.
        if (!locked) {
          // We're not animating, let's lock the modal for animation.
          lockModal();
          // Clear the modal of the open class.
          modal.removeClass('open');

          // Are we using the 'fadeAndPop' animation?
          if (options.animation === 'fadeAndPop') {
            // Yes, okay, let's set the animation properties.
            modal.animate({
              // Set the top property to the document scrollTop minus calculated topOffset.
              top: $doc.scrollTop() - topOffset + 'px',
              // Fade the modal out, by using the opacity property.
              opacity: 0

            },
            // Fade speed.
            options.animationSpeed / 2,
            // End of animation callback.
            function () {
              // Set the css hidden options.
              modal.css(cssOpts.close);
            });
            // Is the modal animation queued?
            if (!modalQueued) {
              // Oh, the modal(s) are mid animating.
              // Let's delay the animation queue.
              modalBg.delay(options.animationSpeed)
              // Fade out the modal background.
              .fadeOut(
              // Animation speed.
              options.animationSpeed,
              // End of animation callback.
              function () {
                // Trigger the modal 'closed' event.
                // This should trigger any method set in the options.closed property.
                modal.trigger('reveal:closed');
              });
            } else {
              // We're not mid queue.
              // Trigger the modal 'closed' event.
              // This should trigger any method set in the options.closed propety.
              modal.trigger('reveal:closed');
            } // end if !modalQueued
          } // end if animation 'fadeAndPop'

          // Are we using the 'fade' animation.
          if (options.animation === 'fade') {
            // Yes, we're using the 'fade' animation.
            modal.animate({ opacity: 0 },
            // Animation speed.
            options.animationSpeed,
            // End of animation callback.
            function () {
              // Set the css close options.
              modal.css(cssOpts.close);
            }); // end animate

            // Are we mid animating the modal(s)?
            if (!modalQueued) {
              // Oh, the modal(s) are mid animating.
              // Let's delay the animation queue.
              modalBg.delay(options.animationSpeed)
              // Let's fade out the modal background element.
              .fadeOut(
              // Animation speed.
              options.animationSpeed,
              // End of animation callback.
              function () {
                // Trigger the modal 'closed' event.
                // This should trigger any method set in the options.closed propety.
                modal.trigger('reveal:closed');
              }); // end fadeOut
            } else {
              // We're not mid queue.
              // Trigger the modal 'closed' event.
              // This should trigger any method set in the options.closed propety.
              modal.trigger('reveal:closed');
            } // end if !modalQueued
          } // end if animation 'fade'

          // Are we not animating?
          if (options.animation === 'none') {
            // We're not animating.
            // Set the modal close css options.
            modal.css(cssOpts.close);
            // Is the modal in the middle of an animation queue?
            if (!modalQueued) {
              // It's not mid queueu. Just hide it.
              modalBg.css({ display: 'none' });
            }
            // Trigger the modal 'closed' event.
            // This should trigger any method set in the options.closed propety.
            modal.trigger('reveal:closed');
          } // end if not animating
          // Reset the modalQueued variable.
          modalQueued = false;
        } // end if !locked
      } // end closeAnimation

      /*
       * Destroys the modal and it's events.
       *
       * @method destroy
       */
      function destroy() {
        // Unbind all .reveal events from the modal.
        modal.off('.reveal');
        // Unbind all .reveal events from the modal background.
        modalBg.off('.reveal');
        // Unbind all .reveal events from the modal 'close' button.
        $closeButton.off('.reveal');
        // Unbind all .reveal events from the body.
        $('body').off('.reveal');
      }

      function closeVideos() {
        var video = modal.find('.flex-video'),
            iframe = video.find('iframe');
        if (iframe.length > 0) {
          iframe.data('src', iframe.attr('src'));
          iframe.attr('src', '');
          video.fadeOut(100);
        }
      }

      // Bind the modal 'close' event.
      // Handled by the options.close property function.
      modal.on('reveal:close.reveal', options.close);
      // Bind the modal 'close' event
      modal.on('reveal:close.reveal', closeAnimation);
      modal.on('reveal:closed.reveal', closeVideos);
      // Bind the modal 'opened' + 'closed' event
      // Calls the unlockModal method.
      modal.on('reveal:opened.reveal reveal:closed.reveal', unlockModal);
      // Bind the modal 'closed' event.
      // Calls the destroy method.
      modal.on('reveal:closed.reveal', destroy);
      // Bind the modal 'open' event
      // Handled by the options.open property function.
      modal.on('reveal:open.reveal', options.open);
      // Bind the modal 'opened' event.
      // Handled by the options.opened property function.
      modal.on('reveal:opened.reveal', options.opened);
      // Bind the modal 'closed' event.
      // Handled by the options.closed property function.
      modal.on('reveal:closed.reveal', options.closed);

      // We're running this for the first time.
      // Trigger the modal 'open' event.
      modal.trigger('reveal:open');

      // Get the closeButton variable element(s).
      $closeButton = $('.' + options.dismissModalClass)
      // Bind the element 'click' event and handler.
      .on('click.reveal', function () {
        // Trigger the modal 'close' event.
        modal.trigger('reveal:close');
      });

      // Should we close the modal background on click?
      if (options.closeOnBackgroundClick) {
        // Yes, close the modal background on 'click'
        // Set the modal background css 'cursor' propety to pointer.
        // Adds a pointer symbol when you mouse over the modal background.
        modalBg.css({ cursor: 'pointer' });
        // Bind a 'click' event handler to the modal background.
        modalBg.on('click.reveal', function () {
          // Trigger the modal 'close' event.
          modal.trigger('reveal:close');
        });
      }

      // Bind keyup functions on the body element.
      // We'll want to close the modal when the 'escape' key is hit.
      $('body').on('keyup.reveal', function (event) {
        // Did the escape key get triggered?
        if (event.which === 27 && options.closeOnEsc) {
          // 27 is the keycode for the Escape key
          // Escape key was triggered.
          // Trigger the modal 'close' event.
          modal.trigger('reveal:close');
        }
      }); // end $(body)
    }); // end this.each
  }; // end $.fn
})(jQuery);
